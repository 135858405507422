body {
  font-family: "Inter", sans-serif;
}

.text-transform-none {
  text-transform: none;
}

.share-qrcode svg {
  width: 100%;
}

.container-lg {
  max-width: 1140px;
}

.navbar {
  position: relative;
  z-index: 1001;
  padding: 0;
  background-color: var(--bs-white);
}
.navbar .container {
  max-width: 100%;
}

.navbar-brand-logo {
  max-height: 40px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 9;
}
.navbar-brand img {
  max-height: 54px;
}
@media (max-width: 370px) {
  .navbar-brand img {
    max-width: 100px;
  }
}
@media (max-width: 991px) {
  .navbar-brand img {
    max-height: 54px;
  }
}

.navbar-brand-wrapper {
  display: flex;
  align-items: center;
  height: 76px;
  width: 100%;
}

.navbar-sponsor-logo img {
  max-width: 100%;
  max-height: var(--header-height, 76px);
}
.links-style {
  font-family: "Inter", sans-serif !important;
  letter-spacing: 1px;
  color: #377dff;
  margin-right: 5px;
  text-decoration: none;
}
.links-style.active:after {
  background-color: #377dff;
}
@media (min-width: 992px) {
  .links-style.active:after {
    width: 100%;
  }
}
.links-style:after {
  background-color: initial;
  content: "";
  display: block;
  height: 2px;
  margin: auto;
  transition: width 0.5s ease, background-color 0.5s ease;
  width: 0;
}
@media (min-width: 992px) {
  .links-style:hover::after {
    background-color: #377dff;
    width: 100%;
  }
}

.navbar-toggler svg {
  fill: var(--bs-white);
  width: 40px;
  height: 40px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: top 0.3s ease;
}

[aria-expanded=true] .navbar-toggler-default {
  display: none;
}

[aria-expanded=false] .navbar-toggler-toggled {
  display: none;
}

.test {
  background: red;
}

.parner-watch {
  max-height: 76px;
}
@media (min-width: 992px) {
  .parner-watch {
    border-bottom: 1px solid #1b6039;
  }
}
@media (max-width: 992px) {
  .parner-watch {
    display: none;
  }
}

.watch-partner-small {
  position: relative;
  display: block;
  width: 100%;
  height: 78px;
  z-index: 99;
  align-items: center;
  padding: 0;
  text-align: center;
  background: #1b6039;
  overflow: hidden;
}
.watch-partner-small iframe {
  overflow: hidden;
}
@media (min-width: 992px) {
  .watch-partner-small {
    display: none;
  }
}

.customers-header {
  background-color: var(--main-color-bg);
}

.custon-info-message a {
  color: #fff;
  text-decoration: none;
}

.custon-info-message a:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
  width: 100%;
}

.links-style {
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  transition: color 0.3s ease;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  margin: 0;
}
@media (max-width: 992px) {
  .links-style {
    padding: 5px 0;
  }
}

.links-style:hover {
  color: #cecece;
  background-color: transparent;
}

.links-style:hover:after {
  background-color: transparent;
}

.links-style.active {
  color: #cecece;
}

.links-style.active:after {
  background-color: transparent;
}

.navbar-nav {
  flex-grow: initial;
  margin: auto;
}

.customers-header {
  height: 76px;
}

.customers-header .navbar-toggler {
  color: #fff;
}

.custom-navbar {
  display: flex;
  list-style-type: none;
  overflow: hidden;
  gap: 15px;
  margin: auto 0;
}

.custom-dropdown:hover svg {
  fill: #cecece;
}

.custom-dropdown svg {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.custom-dropdown-content {
  display: none;
  position: fixed;
  z-index: 9999;
}

.custom-dropdown:hover .custom-dropdown-content {
  display: block;
}

.custom-column {
  padding: 30px 0 20px;
  width: 230px;
}

.custom-column a {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.custom-column a:hover {
  background-color: #cd0022;
}

@media (max-width: 991px) {
  .customers-header .navbar-collapse {
    margin-left: -10px !important;
    top: 76px;
  }
  .customers-header .custom-navbar {
    flex-direction: column;
    padding-bottom: 20px;
    gap: 0;
  }
  .customers-header button {
    padding: 0;
  }
  .customers-header .custom-column {
    width: 100%;
    padding: 0;
  }
  .customers-header .custom-dropdown-content {
    display: block;
    position: relative;
  }
  .custom-column a {
    padding: 5px 20px;
  }
}
.btn-live {
  position: relative;
  width: 80px;
  color: #2e2e2e !important;
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 50px;
  transition: background-color 2s linear;
}
.btn-live:hover {
  background-color: #efefef !important;
}
.btn-live:hover .live-icon {
  animation: none;
}

.live-icon {
  position: absolute;
  right: 12px;
  width: 10px;
  height: 10px;
  transform: translateY(50%);
  border-radius: 100%;
  background-color: var(--main-color-bg);
  animation: live 2s ease-in-out infinite;
}

@keyframes live {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.icon-star {
  cursor: pointer;
}
.icon-star svg {
  pointer-events: none;
  fill: var(--bs-gray-400);
  width: 20px;
  height: 20px;
}
@media (max-width: 468px) {
  .icon-star svg {
    width: 16px;
    height: 16px;
  }
}
.icon-star:hover svg {
  fill: var(--bs-gray-600);
}

.active-row .icon-star svg {
  fill: var(--bs-yellow);
}

.table-style {
  table-layout: fixed;
  border-color: #eaeaea;
  font-size: 80%;
}
@media (min-width: 576px) {
  .table-style {
    font-size: 100%;
  }
}
.table-style thead tr {
  height: 49px;
}
.table-style thead {
  text-transform: uppercase;
  font-size: 80%;
  background-color: var(--main-color-bg);
}
.table-style thead th {
  color: var(--bs-white);
}
.table-style tbody svg {
  transition: fill 200ms ease-in;
}
.table-style tbody tr:hover {
  background-color: rgba(232, 232, 232, 0.2);
}

.title-row {
  background-color: var(--bs-gray-300);
  font-size: 80%;
}

.table-lg > :not(caption) > * > * {
  padding: 0.5rem 1rem;
}
@media (max-width: 700px) {
  .table-lg > :not(caption) > * > * {
    padding: 0.3rem 0.3rem;
  }
}

.auto-fullname {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 24px !important;
}

.icon-legend {
  position: absolute;
  display: flex;
  right: -16px;
}

.player-name-header {
  padding-right: 10px;
}

.player-icon-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
}
.player-icon-header svg {
  width: 15px;
  height: 15px;
}

.player-icon {
  margin-left: 5px;
}
.player-icon svg {
  width: 15px;
  height: 15px;
}

.player-name-info {
  padding-left: 5px !important;
}
.player-name-info .player-icon-header {
  margin-left: -2px;
}

.leaderboard-content {
  font-size: 90%;
}
.leaderboard-content .icon-legend {
  right: 0;
}
.leaderboard-content [data-id-flightnumber] {
  cursor: pointer;
}
.leaderboard-content .top-five-fullname {
  position: relative;
  display: flex;
  align-items: center;
}

.auto-head,
.top-five-head {
  font-size: 72% !important;
}

.top-five-head {
  position: sticky;
  top: 0;
  z-index: 1;
  text-transform: uppercase;
  align-items: center;
  color: var(--bs-white);
  background-color: var(--main-color-bg);
}
.top-five-head .top-five-par72 {
  line-height: 110%;
}

.row-item:hover {
  background: #ededed !important;
}

.auto-selected {
  background: #ededed !important;
}

.table-players tbody tr:nth-child(even),
.table-favorites tbody tr:nth-child(even) {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

@media (max-width: 568px) {
  .round-column {
    display: none;
  }
}

.round-select {
  max-width: 120px;
}

[data-fav=true],
[data-fav-autocomplete=true] {
  background-color: #fff7d5 !important;
  border-bottom: 1px solid #ffe677;
}
[data-fav=true]:hover,
[data-fav-autocomplete=true]:hover {
  background-color: #ffed9b !important;
}

.small-text {
  font-size: 12px;
}

.table-favorites th,
.table-players th {
  font-weight: normal;
}

.table-players tbody tr,
.table-favorites tbody tr {
  position: relative;
}
.table-players tbody tr::after,
.table-favorites tbody tr::after {
  position: absolute;
  content: "";
  left: 0;
  height: 100%;
  border-left: 5px solid var(--course-color);
}

.layer-courses {
  gap: 2px 10px;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .layer-courses {
    margin-bottom: -30px;
  }
}
.layer-courses .button-layer {
  border-left: 28px solid var(--color-button-layer);
  padding: 2px 10px;
  white-space: nowrap;
}

.footer-color {
  background-color: var(--main-color-bg) !important;
}

@media (max-width: 480px) {
  footer {
    font-size: 75%;
  }
}
footer a {
  position: relative;
  text-decoration: none;
  padding-bottom: 2px;
}

footer a:hover {
  color: #f2f2f2;
  border-bottom: 1px solid #fff;
}

footer a::after {
  position: absolute;
  content: "/";
  color: #c7c7c7;
  right: -18px;
  top: -2px;
}

footer ul li:last-child a::after {
  display: none;
}

.list-separator .list-inline-item:not(:last-child) {
  padding-right: 1rem;
}

.site-locator .scroll-to-top {
  display: none;
}

.scroll-to-top {
  position: fixed;
  border-radius: 50%;
  background-color: var(--bs-white);
  cursor: pointer;
  opacity: 0;
  width: 48px;
  height: 48px;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  transform: translateY(100px);
  transition: transform 0.5s ease;
  border: 2px solid var(--main-color-bg, --bs-white);
}
.scroll-to-top svg {
  fill: var(--main-color-bg, --bs-white);
  width: 34px;
  height: 34px;
}

.show-scroll-to-top {
  opacity: 0.8;
  transform: translateY(0);
}
.show-scroll-to-top:hover {
  opacity: 1;
}

.leaflet-control-locate {
  display: none;
}

.leaflet-control-locate-location svg {
  width: 25px !important;
  height: 25px !important;
}

.leaflet-control-locate a {
  cursor: pointer;
}
.leaflet-control-locate a .leaflet-control-locate-location-arrow {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 7px;
  background-color: black;
  mask-image: url("/images/location-arrow-solid.svg");
  mask-repeat: no-repeat;
  mask-position: center;
}
.leaflet-control-locate a .leaflet-control-locate-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 7px;
  background-color: black;
  mask-image: url("/images/spinner-solid.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  animation: leaflet-control-locate-spin 2s linear infinite;
}
.leaflet-control-locate.active a .leaflet-control-locate-location-arrow {
  background-color: #2074b6;
}
.leaflet-control-locate.following a .leaflet-control-locate-location-arrow {
  background-color: #fc8428;
}

.leaflet-touch .leaflet-bar .leaflet-locate-text-active {
  width: 100%;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
}
.leaflet-touch .leaflet-bar .leaflet-locate-text-active .leaflet-locate-icon {
  padding: 0 5px 0 0;
}

.leaflet-control-locate-location circle {
  animation: leaflet-control-locate-throb 4s ease infinite;
}

@keyframes leaflet-control-locate-throb {
  0% {
    stroke-width: 1;
  }
  50% {
    stroke-width: 3;
    transform: scale(0.8, 0.8);
  }
  100% {
    stroke-width: 1;
  }
}
@keyframes leaflet-control-locate-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-info {
  color: #fff;
  width: 100%;
  background-color: #8a0719;
  display: none;
}

.custon-info-message {
  max-width: 1140px;
  padding: 3px 10px;
  margin: auto;
  font-size: 90%;
  max-height: 28px;
  overflow: hidden;
}

[data-info=true] .leaflet-control-zoom {
  margin-top: 50px !important;
}
[data-info=true] .custom-info {
  display: block;
}

.site-locator .custom-info {
  position: absolute;
  z-index: 999;
}
@media (max-width: 991px) {
  .site-locator .custom-info {
    position: relative;
  }
}

.message-partner {
  position: relative;
  width: 100%;
  z-index: 999;
  top: var(--header-height, 0px);
}

.site-locator .message-partner {
  position: fixed;
}

.section-sponsors {
  background-color: var(--main-color-bg, --bs-white);
  color: var(--bs-white);
}
.section-sponsors img {
  max-width: 200px;
}

.player-scoring,
.player-favorites {
  cursor: pointer;
}

.player-scoring-td {
  overflow: auto;
  padding: 0 !important;
}

.scoring-table-header {
  color: var(--bs-white);
}

.scoring-table {
  cursor: default;
  vertical-align: baseline;
  margin-bottom: 0;
}

.rounds-scoring {
  font-weight: 600;
}

.scoring-table th {
  padding: 5px;
  font-weight: bold;
}

.scoring-table-body {
  padding: 0;
  margin: 0;
  font-size: 13px;
  text-align: center;
}

.scoring-table-body > tr:nth-child(odd) {
  background-color: var(--bs-white);
}

.scoring-body-gray {
  background: #f6f6f6 !important;
}

.scoring-body-gray-dark {
  background: #f0efef !important;
}

.table-head-scroing {
  color: #fff;
  font-weight: 400;
  padding: 5px;
  font-size: 13px;
  border-style: hidden;
  text-align: center;
  height: 25px;
}

.table-head-scroing td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: var(--main-color-bg);
}

.scoring-table-body tr td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
}
@media (max-width: 600px) {
  .scoring-table-body tr td:first-child {
    background: #fff;
  }
}

.scoring-table-footer {
  border-top: 1px solid #000 !important;
  font-size: 13px;
  background-color: #fff;
}
.scoring-table-footer .legend-table {
  margin: 5px;
  gap: 5px;
}
@media (min-width: 768px) {
  .scoring-table-footer .legend-table {
    gap: 20px;
    justify-content: end;
  }
}
.scoring-table-footer .legend-element {
  display: flex;
  align-items: center;
  gap: 10px;
}
.scoring-table-footer .legend-icon {
  width: 15px;
  height: 15px;
}
.scoring-table-footer .icon-circle {
  border-radius: 50%;
}

.leaderboard-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  line-height: 100%;
  color: #fff;
  margin: auto;
}

.icon-type-circle {
  border-radius: 50%;
}

.icon-eagle {
  background: #f2ba47;
}

.icon-birdie {
  background: #c7332e;
}

.icon-bogey {
  background: #050015;
}

.icon-double-bogey {
  background: #2f63c4;
}

.table-tennis {
  table-layout: auto;
}

.td-defeats {
  background: var(--bs-white);
  width: 10px;
}

.text-rotation {
  color: #1f2223;
  display: block;
  font-size: 11px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: capitalize;
  transform: rotate(-90deg);
}

.navbar-toggler-default,
.navbar-toggler-toggled {
  pointer-events: none;
}

.navbar-container {
  background: var(--main-color-bg);
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
  }
  .navbar-collapse.show-menu {
    height: var(--custom-navbar-height);
  }
  .custom-navbar {
    margin-top: 10px;
  }
  .custom-navbar li {
    padding: 2px 0;
  }
}
.schedule-head {
  background-color: transparent !important;
}
.schedule-head th {
  color: #000 !important;
  font-size: 1.6rem;
}

.schedule-date-number {
  font-size: 130%;
}

.schedule-head-row td {
  background-color: var(--main-color-bg) !important;
  color: var(--bs-white) !important;
}

.schedule-follow-by {
  padding: 0.3rem 0.5rem !important;
  background-color: #e8e8e8;
}

.rydercup,
.rydercup-quickbar {
  width: 185px;
  overflow: hidden;
}
.rydercup svg,
.rydercup-quickbar svg {
  width: 200px;
}

.rydercup_title-top {
  color: #fff;
  font-size: 11px;
}

.rydercup_logo-top {
  margin: 5px auto;
  height: 40px;
}
.rydercup_logo-top img {
  max-height: 40px;
}

.rydercup-quickbar img {
  display: block;
  max-height: 45px;
}

.rydercup-quickbar {
  margin-left: auto;
}

.site-bigscreen {
  overflow: hidden;
}

.big-screen-wrapper {
  height: calc(100vh - 76px);
}

.scoring-squads {
  font-size: 1.4rem;
}
@media (max-width: 710px) {
  .scoring-squads {
    font-size: 1rem;
  }
}
.scoring-squads .squad-eur {
  background: #003c82;
}
.scoring-squads .squad-usa {
  background: #c81414;
}

.table-head-lead .team-eur {
  background: #003c82;
}
.table-head-lead .team-usa {
  background: #c81414;
}

.player-lead svg {
  width: 25px;
  height: 25px;
  fill: #c81414;
}

div[data-match-id] {
  cursor: pointer;
}
div[data-match-id] .round-name + .round-name {
  margin-top: 20px;
}
div[data-match-id]:nth-child(odd) {
  background-color: #f5f5f5;
}
div[data-match-id]:hover .full-name {
  color: #c81414;
}

@media (max-width: 768px) {
  .points-section .row {
    font-size: 80%;
  }
  .points-section svg {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 576px) {
  .points-section svg {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 991px) {
  .players-eur {
    order: 1;
  }
  .players-usa {
    order: 2;
  }
  .leader-points {
    order: 3;
    flex-basis: auto;
  }
}
@media (min-width: 576px) {
  .player-details-wrapper {
    flex-direction: row;
    display: flex;
  }
}
@media (min-width: 992px) {
  .player-details-wrapper {
    flex-direction: column;
  }
}

.player-info-wrapper {
  margin: 10px 0;
  line-height: 100%;
}
.player-info-wrapper img {
  max-width: 100px;
}
.player-info-wrapper .country {
  color: #adb5bd;
  font-size: 80%;
}
@media (min-width: 992px) {
  .player-info-wrapper + .player-info-wrapper {
    padding-top: 15px;
    border-top: 1px solid var(--bs-gray-300);
  }
}

.player-image img {
  display: block;
  margin: auto;
}

.color-standing {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
}

.color-usa {
  background: #c81414;
}

.color-eur {
  background: #003c82;
}

.leader-points-table {
  margin-bottom: 40px;
}

.points-table tbody tr > td {
  border-right: 1px solid #fff !important;
}

.table-points-wrapper {
  border: 1px solid #e7e7e7;
  font-size: 90%;
  overflow-x: auto;
}
.points-leader {
  padding: 0.5rem 0.13rem !important;
}
.points-leader .flag-icon {
  width: 19px;
  height: 19px;
  margin: auto;
}

.points-matches {
  font-size: 85%;
}
.points-matches > td:not(:first-child) {
  padding: 0.5rem 0 !important;
}

.points-table {
  margin: 0 !important;
  caption-side: bottom;
  border-collapse: collapse;
  background: #fafeff;
}
.points-table thead tr:first-child {
  background-color: var(--main-color-bg, #08285d);
  color: #fff;
}
.points-table td:first-child {
  text-align: left;
  width: auto;
  font-weight: bold;
}
.points-table tr td:first-child,
.points-table td:first-child {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
}
.points-table > :not(:first-child) {
  border-top: none !important;
}
.points-table .dark-color td:first-child {
  background-color: var(--main-color-bg, #08285d);
}
.points-table .light-color td:first-child {
  background: #fafeff;
}
.points-table .team-eur {
  background: #003c82;
  color: #fff !important;
}
.points-table .team-eur:empty {
  background: transparent !important;
}
.points-table .team-usa {
  background: #c81414;
  color: #fff !important;
}
.points-table .team-usa:empty {
  background: transparent !important;
}

.points-section-row {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
@media (min-width: 992px) {
  .points-section-row {
    align-items: center !important;
  }
}

.show-players-points {
  color: #c81414;
}
.show-players-points .arrow-icon {
  rotate: 180deg;
}
.show-players-points .player-info-wrapper {
  border-bottom: none;
}

.active-row {
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.point-matches-item {
  font-size: 90%;
}

.leader-head {
  margin: 30px auto 10px;
}
@media (max-width: 992px) {
  .leader-head {
    margin: 10px auto;
  }
}

.table-leaderboard-center {
  table-layout: fixed;
  margin: 0;
  border-color: transparent;
}
.table-leaderboard-center td {
  padding: 0 !important;
}
.table-leaderboard-center .center-table {
  width: 40px;
  background: #f6f6f6;
  text-align: center;
  vertical-align: middle;
}
@media (max-width: 400px) {
  .table-leaderboard-center .center-table {
    width: 20px;
  }
}

.scoring-logo {
  position: relative;
}

.logo-scoring {
  position: absolute;
  max-width: 120px;
  left: 0;
  right: 0;
  margin: auto;
  top: -50px;
}